<template>
  <div class="contact">
    <a class="anchor" id="contact"></a>
    <div class="content container">
      <h2>Contact Us</h2>
      <div class="row">
        <div
          class="col-12 col-lg-5 map border border-5 mb-4"
          title="Map of Caledon, Ontario and area"
        ></div>
        <div
          class="col-12 col-lg-7 d-flex align-items-center justify-content-center"
        >
          <ul class="p-0">
            <li class="text-center px-3">
              <a href="tel:647-886-1705">
                <span><font-awesome-icon :icon="['fas', 'phone-alt']" /></span>
                <p>Call Us</p>
              </a>
            </li>
            <li class="text-center px-3">
              <a href="mailto:rose_attalla@browniesclub.ca">
                <span><font-awesome-icon :icon="['fas', 'envelope']" /></span>
                <p>Email Us</p>
              </a>
            </li>
            <li class="text-center px-3">
              <a href="https://www.facebook.com/Browniesclub">
                <span><font-awesome-icon :icon="['fab', 'facebook']" /></span>
                <p>Follow Us</p>
              </a>
            </li>
            <li class="text-center px-3">
              <a class="action" href="http://browniesclub.ca/api/">
                <span
                  ><font-awesome-icon :icon="['fas', 'calendar-check']"
                /></span>
                <p>Book Now</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <p class="text-center">©2022 Brownie's Club</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$light-red: #f20505;
$red: #d91424;

ul {
  list-style: none;
}
li {
  margin: 5px 0;
  display: inline-block;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
  &:hover {
    color: black;
  }
}
svg {
  width: auto !important;
  height: 5vh;
}
.map {
  background-image: url("~@/assets/map.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.action {
  color: $light-red !important;
  &:hover {
    color: $red !important;
  }
}
</style>
