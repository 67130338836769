<template>
  <div class="container-fluid p-0" id="app">
    <Nav />
    <Hero />
    <About />
    <Services />
    <Friends />
    <Reviews />
    <Contact />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Hero from "./components/Hero.vue";
import About from "./components/About.vue";
import Services from "./components/Services.vue";
import Friends from "./components/Friends.vue";
import Reviews from "./components/Reviews.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    Nav,
    Hero,
    About,
    Services,
    Friends,
    Reviews,
    Contact,
  },
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Raleway:wght@100;300;400;500;700&display=swap");
$raleway: "Raleway", sans-serif;
$open-sans: "Open Sans", sans-serif;

h2 {
  font-family: $raleway;
  font-weight: 300;
  font-size: 2rem;
  margin: 15px 0;
  color: rgb(64, 64, 64);
}
p {
  font-family: $open-sans;
  font-weight: 300;
  font-size: 1rem;
  color: rgb(64, 64, 64);
}
.border {
  border-color: white !important;
}
.anchor {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}
</style>
