<template>
  <div class="services">
    <a class="anchor" id="services"></a>
    <div class="content container">
      <h2>Our Services</h2>
      <div class="row dog-bg d-flex align-items-center">
        <div
          class="col-12 col-lg-3 panel border border-5 d-flex flex-column justify-content-center p-4"
          data-bs-toggle="modal"
          data-bs-target="#boarding"
        >
          <img class="align-self-center" src="@/assets/House.svg" />
          <h3>Boarding &amp; Daycare</h3>
          <div
            class="modal fade"
            id="boarding"
            tabindex="-1"
            aria-labelledby="Boarding and Daycare"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <p>
                    Short and long-term stay options are available. We offer
                    homebased, 24/7 care for as long as you need us.
                  </p>
                  <p>
                    No need to kennel your dog to take a vacation. Call us
                    instead!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 grid">
          <div class="row">
            <div
              class="col-6 panels border border-5 d-flex flex-column justify-content-center p-4"
              data-bs-toggle="modal"
              data-bs-target="#pickup"
            >
              <img class="align-self-center" src="@/assets/Car.svg" />
              <h3>Pick-up &amp; Drop-off</h3>
              <div
                class="modal fade"
                id="pickup"
                tabindex="-1"
                aria-labelledby="Pick-up and Drop-off"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <p>
                        Does your pet have an appointment with the vet or a
                        groomer? We’ll take them and bring them back to you
                        safely.
                      </p>
                      <p>
                        We also offer free* pick-up and drop-off for dogs going
                        to daycare or boarding at Brownie's Club.
                      </p>
                      <p class="small">
                        *Small fee applies for residents living more than 10km
                        from our location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 panels border border-5 d-flex flex-column justify-content-center p-4"
              data-bs-toggle="modal"
              data-bs-target="#consult"
            >
              <img class="align-self-center" src="@/assets/Phone.svg" />
              <h3>Consulting</h3>
              <div
                class="modal fade"
                id="consult"
                tabindex="-1"
                aria-labelledby="Consulting"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <p>
                        We are committed to teaching owners how to keep pets
                        healthy and safe by promoting responsible pet ownership.
                      </p>
                      <p>
                        We offer the following:
                        <br />
                        - Potty training
                        <br />
                        - Puppy parenting classes
                        <br />
                        - Puppy tricks training
                        <br />
                        - Budgeting for your pets
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-6 panels border border-5 d-flex flex-column justify-content-center p-4"
              data-bs-toggle="modal"
              data-bs-target="#sitting"
            >
              <img class="align-self-center" src="@/assets/Cat.svg" />
              <h3>Pet Sitting</h3>
              <div
                class="modal fade"
                id="sitting"
                tabindex="-1"
                aria-labelledby="Pet Sitting"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <p>
                        We will come over to your home and make sure that your
                        pet is fed, given water, and pampered while you are out
                        and about.
                      </p>
                      <p>You will return to a happy and satisfied companion.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 panels border border-5 d-flex flex-column justify-content-center p-4"
              data-bs-toggle="modal"
              data-bs-target="#walking"
            >
              <img class="align-self-center" src="@/assets/Dog.svg" />
              <h3>Dog Walking</h3>
              <div
                class="modal fade"
                id="walking"
                tabindex="-1"
                aria-labelledby="Dog Walking"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <p>
                        We offer dog-walking services daily, weekly, or longer.
                      </p>
                      <p>
                        If you are unable to walk them every day, for any
                        reason, we will take care of that for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-lg-3 panel border border-5 d-flex flex-column justify-content-center p-4"
          data-bs-toggle="modal"
          data-bs-target="#emergency"
        >
          <img class="align-self-center" src="@/assets/Aid.svg" />
          <h3>Emergency Service</h3>
          <div
            class="modal fade"
            id="emergency"
            tabindex="-1"
            aria-labelledby="Emergency Service"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <p>
                    “On call” service available in some unique circumstances.
                  </p>
                  <p>
                    Experiencing an emergency and need to have your pets taken
                    care of? Call us. We will help.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$open-sans: "Open Sans", sans-serif;

.dog-bg {
  background-image: url("~@/assets/Dog_BG.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
h3 {
  font-family: $open-sans;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: white;
}
img {
  height: 5vh;
  margin-bottom: 10px;
}
p {
  text-align: center;
}
.panel {
  height: 80vh;
  background-color: rgba(16, 58, 140, 0.75);
  text-align: center;
  &:hover {
    cursor: context-menu;
  }
}
.grid {
  background-color: rgba(18, 123, 191, 0.75);
}
.panels {
  height: 40vh;
  text-align: center;
  &:hover {
    cursor: context-menu;
  }
}
.border {
  border-color: white !important;
}
.small {
  font-size: 10px;
}
@media (max-width: 992px) {
  .panel {
    height: 40vh;
  }
  .dog-bg {
    background-image: url("~@/assets/Dog_Mobile.jpeg");
  }
}
@media (max-width: 768px) {
  .panel {
    height: 30vh;
  }
  .panels {
    height: 30vh;
  }
}
</style>
