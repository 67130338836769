<template>
  <div class="reviews">
    <a class="anchor" id="reviews"></a>
    <div class="content container">
      <h2>Testimonials</h2>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 grid p-0">
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                I would highly recommend Brownie’s club. Rose is very reliable,
                flexible, and friendly. Rose has done an excellent job keeping
                me informed and sending pictures of Coco. She came on her way to
                pick Coco up. I really appreciate her time.<strong>
                  If you need your pets taken care of while in or out of town,
                  Brownie’s Club is for you.</strong
                >
              </p>
              <p class="name m-0 p-0"><strong>Hala</strong></p>
              <p class="date m-0 p-0">November 30, 2021</p>
            </div>
          </div>
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                <strong>I'm convinced Rose is a dog whisperer!</strong> She knew
                exactly what my puppy was going through and how to best train
                and engage him in a safe and positive way. She was also flexible
                in training him by initially coming to my house and getting to
                know him to eventually taking him to her house and continue the
                training. Would highly recommend her!
              </p>
              <p class="name m-0 p-0"><strong>Babita</strong></p>
              <p class="date m-0 p-0">April 21, 2021</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 grid2 p-0">
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                Rose ensures that the dogs feel that Brownie's club is their
                home away from home.
                <strong>She always kept me updated during my dog’s stay</strong>
                and I saw how much fun he was having with all the other dogs! I
                loved seeing him playing and socializing. He came home happy,
                healthy and with an even greater sense of routine.
              </p>
              <p class="name m-0 p-0"><strong>Miray</strong></p>
              <p class="date m-0 p-0">July 30, 2020</p>
            </div>
          </div>
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                Rose was super accommodating during our puppy's one night stay!
                She also let us do a trial to make sure he would be okay! She
                took great care of our little guy and it’s also a plus that he
                has other friends to play with while there teaching him how to
                socialize!
                <strong
                  >Overall great experience and very affordable price
                  point!</strong
                >
                We would highly recommend Brownie's Club!
              </p>
              <p class="name m-0 p-0"><strong>Samantha</strong></p>
              <p class="date m-0 p-0">November 4, 2019</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 grid hide p-0">
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                Brownie’s Club with owner Rose in command is absolutely the best
                place ever for your puppies when you need to leave them either
                just for the day or many days in a row.
                <strong
                  >She cares for and loves them as if they were her own.</strong
                >
                Lots of time spent outside either in the backyard or going for
                walks. Highly recommend if you are wanting lots of TLC.
              </p>
              <p class="name m-0 p-0"><strong>Pat</strong></p>
              <p class="date m-0 p-0">July 23, 2019</p>
            </div>
          </div>
          <div class="col-12 review border border-5">
            <div class="inner py-4 px-4">
              <img class="quote mb-2" src="@/assets/Quote.svg" />
              <p class="my-4">
                <strong
                  >I really trust to leave my dog at Brownie’s Club when I’m
                  away.</strong
                >
                Doesn’t matter how long I leave him there, he's always clean and
                they really take care of him same as he’s home. Really recommend
                it to anyone needs a place to leave their loved ones in a safe
                place.
              </p>
              <p class="name m-0 p-0"><strong>Dalia</strong></p>
              <p class="date m-0 p-0">March 16, 2019</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
  color: white;
}
.quote {
  width: 25px;
}
.name {
  font-size: 0.9rem;
}
.date {
  font-size: 0.9rem;
}
.review:nth-child(odd) .inner {
  background-color: rgb(16, 58, 140);
}
.review:nth-child(even) .inner {
  background-color: rgb(18, 123, 191);
}
.grid2 .review:nth-child(even) .inner {
  background-color: rgb(16, 58, 140);
}
.grid2 .review:nth-child(odd) .inner {
  background-color: rgb(18, 123, 191);
}
.review {
  overflow: hidden;
  border-radius: 25px;
}
.border {
  border-color: white !important;
}
b,
strong {
  font-weight: bold !important;
}
@media only screen and (max-width: 992px) {
  .hide {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .grid2 {
    display: none;
  }
  .hide {
    display: block;
  }
}
</style>
