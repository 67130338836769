<template>
  <div
    id="home"
    class="d-flex flex-column align-items-center justify-content-start"
  >
    <h1>
      <img
        class="logo"
        src="@/assets/BC_Logo_C.svg"
        alt="Brownie's Club: Pet Daycare, Boarding, and Grooming"
      />
    </h1>
    <h2 class="mobile text-center">
      Serving Caledon<br />
      and surrounding areas
    </h2>
    <h2 class="d-none d-md-block">Serving Caledon and surrounding areas</h2>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  min-height: 95vh;
  background-image: url("~@/assets/hero_bg2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
img {
  width: 45vw;
  margin-top: 15vh;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 992px) {
  img {
    width: 60vw;
    max-width: 550px;
  }
}
@media only screen and (max-width: 768px) {
  img {
    width: 90vw;
    max-width: 500px;
  }
  .mobile {
    display: block;
  }
}
</style>
