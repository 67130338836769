<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div class="collapse navbar-collapse order-1 order-lg-0 dual-collapse">
        <ul
          class="navbar-nav d-flex align-items-center flex-grow-1 justify-content-evenly"
        >
          <li class="nav-item">
            <a class="nav-link" href="#about">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Our Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#friends">Brownie's Friends</a>
          </li>
        </ul>
      </div>
      <div class="order-0 d-flex">
        <a class="navbar-brand p-0" href="#home">
          <img class="logo" src="@/assets/B_Logo_C.svg" />
        </a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target=".dual-collapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse order-3 dual-collapse">
        <ul
          class="navbar-nav d-flex align-items-center flex-grow-1 justify-content-evenly"
        >
          <li class="nav-item">
            <a class="nav-link" href="#reviews">Testimonials</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-2 mb-lg-0 action"
              href="https://browniesclub.ca/api/"
              >Book an Appointment</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Nav",
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Raleway:wght@100;400;500;700&display=swap");

$raleway: "Raleway", sans-serif;
$light-red: #f20505;
$red: #d91424;

.navbar {
  padding: 0;
}
.navbar-brand {
  margin-right: 0;
}
.logo {
  width: 80px;
}
.nav-item {
  font-family: $raleway;
  font-weight: 500;
  a {
    text-align: center;
    font-size: 1rem;
  }
}
.action {
  color: $light-red !important;
  &:hover {
    color: $red !important;
  }
}
button {
  border: none;
  &:focus {
    outline: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    flex-basis: 0;
  }
}
</style>
