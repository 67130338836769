<template>
  <div class="friends">
    <a class="anchor" id="friends"></a>
    <div class="content container">
      <h2>Brownie's Friends</h2>
      <div class="row">
        <div class="col-12 col-lg-6 grid">
          <div class="row">
            <div
              class="col-6 image border border-5"
              id="dog1"
              title="Cooper sitting on a chair outdoors"
            ></div>
            <div
              class="col-6 image border border-5"
              id="dog2"
              title="Milo posing for the camera"
            ></div>
            <div
              class="col-12 image border border-5"
              id="dog3"
              title="Benzo wrapped up in a blanket"
            ></div>
          </div>
        </div>
        <div class="col-12 col-lg-6 grid">
          <div class="row">
            <div
              class="col-6 image border border-5"
              id="dog4"
              title="Spotty relaxing on the windowsill"
            ></div>
            <div
              class="col-6 image border border-5"
              id="dog5"
              title="Zoe laying on a chair"
            ></div>
            <div
              class="col-12 image border border-5"
              id="dog6"
              title="Jerry stretching on the floor"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
}
#dog1 {
  background-image: url("~@/assets/dog1.jpg");
  background-position: top center;
}
#dog2 {
  background-image: url("~@/assets/dog2.jpg");
  background-position: center center;
}
#dog3 {
  background-image: url("~@/assets/dog3.jpg");
  background-position: top left;
}
#dog4 {
  background-image: url("~@/assets/dog4.jpg");
}
#dog5 {
  background-image: url("~@/assets/dog5.jpg");
}
#dog6 {
  background-image: url("~@/assets/dog6.jpg");
}
@media (max-width: 768px) {
  .image {
    height: 35vh;
  }
}
</style>
