<template>
  <div class="about">
    <a class="anchor" id="about"></a>
    <div class="content container">
      <div
        class="row d-flex my-0 my-md-4 justify-content-center align-items-center"
      >
        <div
          class="col-12 col-lg-6 border border-5 brownie .bg-image"
          title="Brownie posing in a field with fallen leaves"
        ></div>
        <div class="col-12 col-lg-6">
          <h2>About Us</h2>
          <p>
            Brownie’s Club owner Rose Attalla is passionate about all animals
            and dedicated to helping others. She provides a heart-centered,
            loving and home based environment for long and short-term stays for
            the special pet in your life.
          </p>
          <p>
            Leave your pet with us knowing your fur baby is in good hands, in a
            safe space with all the benefits of home and none of the noisy
            distractions of kennel based care.
          </p>
          <p class="text-center">
            <img class="paws" src="@/assets/Paws.svg" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  padding-top: 40px;
}
.brownie {
  background-image: url("~@/assets/Brownie.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  min-height: 400px;
}
.paws {
  width: 240px;
}
@media only screen and (max-width: 768px) {
  .brownie {
    height: 30vh;
    min-height: 300px;
  }
}
</style>
